import "../src/style.scss"
 
// // Manually generate import statements for all SCSS files in the "components" directory and its subdirectories
// function importAllSCSSFiles() {
//   const context = require.context('../src/style/components/', true, /\.js$/);
//   context.keys().forEach(key => {
//     // const filePath = key.replace(/^\.\//, ''); // Remove the leading './'
//     import(`../src/style/components/${filePath}`);
//   });
// }
// importAllSCSSFiles();

// Import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// Import styles bundle
import 'swiper/css/bundle';


